export enum OpreateEnum {
  ALL = 1, //抖音和美团都有
  TIKTOK = 2,
  MEI_TUAN = 3,
  UN_BIND = 4, // 均未绑定
}

export const OPREATE_CHANNEL_OPTIONS = [
  {
    label: "仅抖音",
    value: OpreateEnum.TIKTOK,
  },
  {
    label: "仅美团",
    value: OpreateEnum.MEI_TUAN,
  },
  {
    label: "抖音/美团都有",
    value: OpreateEnum.ALL,
  },
  {
    label: "均未绑定",
    value: OpreateEnum.UN_BIND,
  },
];
