export enum StatusEnum {
  DISABLE = 0,
  ENABLE = 1,
}

/** 商户类型 */
export enum MerchantTypeEnum {
  /** 平台 */
  PLATFORM = 1,
  /** 商户 */
  SELLER = 2,
}

/** 路由操作 */
export enum RouteActionEnum {
  CREATE,
  DETAIL,
  EDIT,
}

export enum PublicEnum {
  PUBLIC = 1,
  UNPUBLIC = 2,
}
export const MERCHANT_TYPE_OPTIONS = [
  {
    label: "平台",
    value: MerchantTypeEnum.PLATFORM,
  },
  {
    label: "商家",
    value: MerchantTypeEnum.SELLER,
  },
];

export const STATUS_OPTIONS = [
  {
    label: "已停用",
    value: StatusEnum.DISABLE,
  },
  {
    label: "已启用",
    value: StatusEnum.ENABLE,
  },
];
